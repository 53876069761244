<template>
</template>
<script>
import {
    getJsapiTicket,
    getMaterialApplicationJsapi,
    getBindList,

} from "@/api/binging";
import {Notify} from "vant";

export default {
    name: 'singleSignOn',
    data() {
        return {
            token: '',
            userId: '',
        }
    },
    created() {
        this.$showLoad(true)
        // this.token = this.$route.query.token || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiIxMjIxIiwiYXVkIjoiIiwiZXhwIjoxNzQyNTAyODUzLCJpYXQiOjE3NDI0NDI4NTMsImlzcyI6IiIsImp0aSI6Ijc2YjFmODA1YmMwMjI3NTUxOGYxNGZlNGRhNjU2YWQ2IiwibmJmIjoxNzQyNDQyODUzLCJzdWIiOiIifQ.-1kvFnkRwWrvbweyB3Solqx9BJ5t6mCP9VbzV7pxv2E'
        this.token = this.$route.query.token
        if (this.token && this.$route.query.type){
            this.getToken()
        }
        else if (this.$route.query.type) {
            let login_url = process.env.VUE_APP_BASE_URL_JAVA + '/crm/qwxCustomerDetail?type=' + this.$route.query.type
            window.location.replace(
                'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + process.env.VUE_APP_COMPANY_APPID_WX + '&redirect_uri=' + encodeURIComponent(login_url) + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect',
            );
            // location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + process.env.VUE_APP_COMPANY_APPID_WX + '&redirect_uri=' + encodeURIComponent(login_url) + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
        }
        else {
            this.$router.replace(
                {
                    path: '/errorPage',
                    query: {
                        message: '请在企业微信聊天工具栏打开',
                    }
                }
            )
        }
    },
    methods: {
        // 获取当前客户绑定状态
        getCustomerInfo(){
            let para = {
                qw_customer_id: this.userId,
                type: '',
                select_text: '',
            }

            getBindList(para).then(res=>{
                if (res.code === 200){
                    // let customer_id = '174600'
                    let customer_id = res.data.customer_id
                    if (this.$route.query.type == 1){ // 跳转客户详情
                        if (customer_id){ // 成功获取序号 跳转客户详情
                            this.$router.replace(
                                {
                                    path: '/customerInfo',
                                    query: {
                                        customer_id: customer_id,
                                    }
                                }
                            )

                        }
                        else { // 获取失败 跳转客户绑定
                            this.$router.replace(
                                {
                                    path: '/customerBinding',
                                    query: {
                                        userId: this.userId,
                                    }
                                }
                            )
                        }
                    }
                    else if (this.$route.query.type == 2){// 跳转跟单记录
                        this.$router.replace(
                            {
                                path: '/documentaryRecord',
                                query: {
                                    customer_id: customer_id || '',
                                    customer_name: customer_id ? res.data.customerInfo.user_name : '',
                                }
                            }
                        )
                    }
                }else {
                    Notify({type: 'danger', message: res.message})
                }
            }).catch((err) => {
                console.log(err)
                Notify({type: 'warning', message: '服务器连接失败'})
            })

            // 根据客户企微id 查找crm的客户序号
            // 获取 客户序号

        },
        getToken(){
            sessionStorage.setItem('token', "bearer " + this.token) // 存储token信息
            // // this.userId = 'wm43sGDQAA3mqoeX3hJeR_42wl29upcA'
            // this.userId = 'wm43sGDQAAYlx7nzTQnPaT4MMUynopbQ'
            // this.getCustomerInfo()
            // return
            let para ={
                url: String(location.href.split('#')[0])
            }
            this.$showLoad(true)
            getJsapiTicket(para).then(res => {
                if (res.code === 200) {
                    let that = this
                    wx.config({
                        beta: true,
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: process.env.VUE_APP_COMPANY_APPID_WX, // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
                        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: [
                            'getCurExternalContact',
                        ] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                    //配置成功以后config:ok
                    wx.ready(function () {
                        getMaterialApplicationJsapi(para).then(res2 => {
                            // alert('agentConfig验证返回'+JSON.stringify(res2))
                            wx.agentConfig({
                                corpid: process.env.VUE_APP_COMPANY_APPID_WX, // 必填，企业微信的corpid，必须与当前登录的企业一致
                                agentid: process.env.VUE_APP_AGENT_id, // 必填，企业微信的应用id （e.g. 1000247）
                                timestamp: res2.data.timestamp, // 必填，生成签名的时间戳
                                nonceStr: res2.data.noncestr, // 必填，生成签名的随机串
                                signature: res2.data.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                                jsApiList: [
                                    'getCurExternalContact',
                                ], //必填，传入需要使用的接口名称
                                success: function () {
                                    // 获取当前客户企微id
                                    wx.invoke('getCurExternalContact', {
                                    }, function(data){
                                        // alert(JSON.stringify(data))
                                        if(data.err_msg === "getCurExternalContact:ok"){
                                            that.userId = data.userId
                                            that.getCustomerInfo()
                                        }else {
                                            //错误处理
                                            wx.invoke('getCurExternalContact', {
                                            }, function(data){
                                                that.userId = data.userId
                                                that.getCustomerInfo()
                                            });
                                        }
                                    });
                                },
                                fail: function (err) {
                                    if (err.errMsg.indexOf('function not exist') > -1) {
                                        alert('版本过低请升级')
                                    }
                                }
                            });
                        })
                    })
                    // wx.error(function(res){
                    //     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    // });
                } else {
                    Notify({type: 'danger', message: res.message})
                }
            }).catch((err) => {
                // this.$message.error('服务器连接失败200!')
                Notify({type: 'warning', message: err + '456456'});
            })
        },
    }
}
</script>
