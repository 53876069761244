import {request} from "../service/request"
let url = process.env.VUE_APP_BASE_URL_JAVA // 接口地址
// -获取客户跟单记录列表数据-
export function getDocumentary(id) {
    return request({
        url: url + '/crm/userFollowList?customerId=' + id,
        method: 'get',
    })
}
// 添加、修改跟单记录-
export function addDocumentary(data) {
    return request({
        url: url + '/crm/userFollowSaveUp',
        method: 'post',
        data: data
    })
}
/* E 跟单记录*/
export const getNoticeQueryHistoryList = (params) => {
    return request({
        url: url + '/notice/queryHistoryList',
        method: 'get',
        params
    })
}

export const postNoticeSubscription = (data) => {
    return request({
        url: url + '/notice/subscription',
        method: 'post',
        data
    })
}
export const getEducation = (params) => {
    return request({
        url: url + '/crm/CustomerList/getEducation',
        method: 'get',
        params
    })
}

export const getNumberCountryList = (params) => {
    return request({
        url: url + '/crm/service/department/numberCountryList',
        method: 'get',
        params
    })
}
//  摘星原因
export const getStarDown = () => {
    return request({
        url: url + '/crm/starDown',
        method: 'get'
    })
}
// 签约项目列表
export const signedDataJava = (params) => {
    return request({
        url: url + '/crm/signed_projects/selectList',
        method: 'get',
        params
    })
}

export const callDemo = (data) =>  {
    return request({
        url: url + '/crm/Outbound/outboundCallPhone',
        method: 'post',
        data
    })
}
// 详情（外呼） - 挂断
export const callDown = () =>  {
    return request({
        url: url + '/crm/Outbound/outboundHangUp',
        method: 'post',
    })
}

// 详情（外呼）- 获取user_id
export const getUserId = () =>  {
    return request({
        url: url + '/crm/Outbound/getUid',
        method: 'get',
    })
}

// 详情（外呼） - 判断号码拨打次数
export const callOutboundCallPhone = (data) => {
    return request({
        url: url + '/crm/Outbound/checkOutboundCallPhone',
        method: 'post',
        data
    })
}
// 企业js授权
export const getJsapiTicket = (params) => {
    return request({
        url: url + '/crm/qw/customer/detail/getJsapiTicket',
        method: 'get',
        params
    })
}
// 应用js授权
export const getMaterialApplicationJsapi = (params) => {
    return request({
        url: url + '/crm/qw/customer/detail/applicationJsapi',
        method: 'get',
        params
    })
}
// -获取客户详情-
export const getCustomerInfo = (params) => {
    return request({
        url: url + '/crm/userCustomerDetails',
        method: 'get',
        params
    })
}

// -客户管理--获取客户所在地下拉-
export const getCustomerAddress = () => {
    return request({
        url: url + '/crm/areaList',
        method: 'get',
    })
}
// -客户管理--无效原因下拉-
export const invalidReasonData = () => {
    return request({
        url: url + '/crm/labelDropTwo',
        method: 'get',
    })
}
// -修改客户详情信息-
export const saveCustomerDetail = (data) => {
    return request({
        url: url + '/crm/customerSubmit',
        method: 'post',
        data
    })
}
// -获取客户列表-
export const getBindList = (params) => {
    return request({
        url: url + '/crm/qw/customer/detail/bindList',
        method: 'get',
        params
    })
}
// -绑定客户-
export const postBindCustomer = (data) => {
    return request({
        url: url + '/crm/qw/customer/detail/bindCustomer',
        method: 'post',
        data
    })
}
// -获取客户配偶信息-
export const getCustomerSpouse = (params) => {
    return request({
        url: url + '/crm/customSubmitSearch',
        method: 'get',
        params
    })
}
